<template lang="pug">
</template>

<script>
export default {
    name: "Home",

    components: {},

    created () {
        this.$store.dispatch('startup');
    }
};
</script>
